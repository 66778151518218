// 
// user.scss
// Use this to write your custom SCSS
//

html{
  scroll-padding-top:120px;
}

.icon-style-light {
    background-color: var(--si-secondary);
    color: var(--si-primary);
    padding: 0.5rem;
    border-radius: 0.375rem;
}

@media (max-width: 991px) {
  .list-columns {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .list-columns {
    column-count: 4;
  }
}

.g-content-safe{
  img { @include img-fluid; }

  word-break: break-word;

  table{
    @extend .table;
    @extend .table-bordered;
    @extend .table-responsive;
    word-break: keep-all;
  }

  blockquote {
    font-family: $headings-font-family;
    padding-left:50px;
    margin: 40px 0;
    color: rgba(0,0,0,.68);
    border-left: 5px solid $gray-300;
  }
}

.login-button {
  background-color: #e5dece;
  color: var(--si-dark);
  &:hover {
    background-color: #cac5b7;
  }
}

.color-facebook{color:#3b5998;}
.color-twitter{color:#136394;}
.color-whatsapp{color:#296925;}
.color-mendeley{color:#af122b;}
.color-linkedin{color:#0077b5;}
.color-reddit{color:#ca4401;}
.color-researchgate{color: rgb(1, 136, 125);}

.nav-tabs .nav-link {
    background-color: var(--si-secondary) !important;
}

.nav-tabs .nav-link.active {
    background-color: var(--si-primary) !important;
}

@media (min-width: 768px) {
    .sticky-section {
    position: sticky;
    top: 120px;
    height: fit-content;
  }
  .sticky-section h2 {
    max-width: 500px;
  }
}

// Circular social proof images
.social-proof-image-trio {
  margin-right: 12px;
}
.social-proof-image-trio img:not(:first-of-type) {
  margin-left: -10px;
}
.social-proof-image-trio img {
  border-radius: 50%;
  border: 2px solid var(--si-secondary);
  width: 40px;
  height: 40px;
  object-fit: cover;
}

// About page timeline
.ukessays-timeline .steps .step .step-body h3 {
  line-height: 0.85 !important;
}
.ukessays-timeline .steps .step {
  align-items: unset !important;
}
.ukessays-timeline .steps .step::after {
  height: 100% !important;
  top: unset !important;
}

// Horizontal news slider
.brand-swiper .swiper-slide {
  height: 22px;
  text-align: center;
}
.brand-swiper .swiper-slide img {
  height: 100%;
}

// Styling for Certainly bot
#botxo-chat-1 {
  z-index:6010 !important;
}


// Index Section 2 Slider 
.indexSlidersContainer {
  gap: 25px;
}
.heroSliderLeft,
.heroSliderRight {
  width: 100%;
  height: 500px;
  overflow: visible;
}
.heroSliderLeft .swiper-slide,
.heroSliderRight .swiper-slide {
  height: 400px;
  background-color: white;
}
.heroSliderLeft .swiper-slide img:not(.indexHeroSliderLogo),
.heroSliderRight .swiper-slide img:not(.indexHeroSliderLogo) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  border-radius: 6px;

}
.heroSliderLeft .swiper-slide.smallHeroBox,
.heroSliderRight .swiper-slide.smallHeroBox {
 height: 75px;
}
.heroSliderLeft .swiper-slide.mediumHeroBox,
.heroSliderRight .swiper-slide.mediumHeroBox {
 height: 90px;
}

/* Responsive Jarralax Bug */
@media (max-width: 767px) {
  .login-card {
    position: relative;
  }
}


.accordion-button::after {
  background-color: var(--si-secondary);
}

.press-review-image-circle {
  width: 100px !important;
  object-fit: contain !important;
}

.how-it-works_content img {
  height: 30px;
}

a:hover .animated-link {
  color: var(--si-primary) !important;
}

.ss-sidebar{
  @media (min-width: 768px) {
    top:9rem; 
    height:calc(100vh - 7rem);
  }
}

.menu-sidebar{
  .nav-item-ss {
    text-align:left;
    .nav-link-ss {
      text-decoration:none;
      color:#222;
    } & .active{
      color:#af122b;
      border-left:#af122b solid 1px;
      padding-left:5px;
    }
  }
}
